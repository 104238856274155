import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { PayPalButton } from "react-paypal-button-v2"
import { navigate } from "gatsby"
// import material ui
import {
  Typography,
  Grid,
  CircularProgress,
  FormControl,
  Select,
  Button
} from "@material-ui/core/"

// import app components
import Edges from "../components/edges"
import Spacer from "../components/spacer"
import Product from "../components/product"
import { useStore } from "../store"
import { appServices } from "../services"

const Checkout = () => {
  const [status, setStatus] = useState("checkout")
  const [shippingSelection, setShippingSelection] = useState("")
  const [
    {
      authState,
      appState: {
        cart: { items }
      }
    },
    dispatch
  ] = useStore()

  // total up price of all items
  const itemPrices = items.map(
    ({ product: { price }, amount }) => parseFloat(price) * amount
  )
  const reducer = (accumulator, currentValue) => accumulator + currentValue
  const baseTotal = itemPrices.reduce(reducer, 0)

  useEffect(() => {
    status === "loading" &&
      typeof window !== "undefined" &&
      window.scrollTo({ top: 0, behavior: "smooth" })
  }, [status])

  const onSuccess = async (details, data) => {
    setStatus("loading")
    // Remove unnecessary items props (especially description and image) to reduce request size
    const formattedItems = items.map(
      ({ id, amount, title, product: { price } }) => ({
        id,
        amount,
        title,
        product: {
          price
        }
      })
    )

    const body = {
      ...details,
      shipping_cost: getShipping(),
      items: formattedItems
    }
    // console.log("post", body)
    const result = await appServices.postOrder(body, authState)

    if (result) {
      setStatus("success")
      dispatch({
        type: "EMPTY_CART"
      })
    }
  }

  const getShipping = () => {
    switch (shippingSelection) {
      case "Canada":
        return 3.0
      case "US":
        return 5.0
      case "Europe":
        return 8.0
      case "":
        return false
      default:
        return false
    }
  }

  return (
    <Spacer pt={40} pb={80}>
      <Edges size="md">
        <Spacer pb={20}>
          <Typography variant="h1" children="Checkout" />
        </Spacer>

        {status === "checkout" && (
          <>
            <Spacer pb={30}>
              <Typography variant="h4" component="h2" children="Your order" />
            </Spacer>

            {items.length > 0 ? (
              <Container>
                <Content>
                  <ItemsHeader>
                    <Typography
                      children="Product"
                      variant="caption"
                      className="ItemsHeaderProduct"
                    />
                    <Typography
                      children="Price"
                      variant="caption"
                      className="ItemsHeaderPrice"
                    />
                    <Typography
                      children="QTY"
                      variant="caption"
                      className="ItemsHeaderAmount"
                    />
                    <Typography
                      children="Total"
                      variant="caption"
                      className="ItemsHeaderTotal"
                    />
                  </ItemsHeader>
                  <Items>
                    {items.map((o) => {
                      return (
                        <Item key={o.id}>
                          <Product {...o} target="checkout" />
                        </Item>
                      )
                    })}
                  </Items>

                  <Divider />

                  <Grid
                    container
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <div>
                      <Typography
                        variant="caption"
                        component="div"
                        children="SHIPPING"
                        gutterBottom
                      />
                      <FormControl>
                        <Select
                          native
                          value={shippingSelection}
                          onChange={(e) => setShippingSelection(e.target.value)}
                          inputProps={{
                            name: "age",
                            id: "shipping-selection"
                          }}
                        >
                          <option value="">Select Country</option>
                          <option value="Canada">Canada</option>
                          <option value="US">US</option>
                          <option value="Europe">Europe</option>
                        </Select>
                      </FormControl>
                    </div>
                    <Total>
                      Cart Subtotal: ${baseTotal.toFixed(2)}
                      <br />
                      {!!getShipping() && (
                        <>
                          Shipping: ${getShipping().toFixed(2)}
                          <br />
                        </>
                      )}
                      {!!getShipping() && (
                        <>Total: ${(baseTotal + getShipping()).toFixed(2)}</>
                      )}
                    </Total>
                  </Grid>
                </Content>

                {shippingSelection && (
                  <>
                    <PaymentTitle
                      variant="h4"
                      component="h2"
                      children="Payment"
                    />

                    <PayPalContainer>
                      <PayPalButton
                        amount={(baseTotal + getShipping()).toFixed(2)}
                        onSuccess={onSuccess}
                        options={{
                          // clientId: "sb",
                          clientId:
                            "AS83FksAt5H7CE8Fj8Hwuozt2JsNKlIi_ShQRpUgyE0ak11GsVWQU6Znw706Ru7johzSRLNxsdtP-JpS",
                          currency: "CAD"
                        }}
                      />
                    </PayPalContainer>
                  </>
                )}
              </Container>
            ) : (
              <Typography children="No items in cart" />
            )}
          </>
        )}

        {status === "loading" && (
          <Spacer pt={60} pb={60}>
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
            <LoadingText variant="h4">
              Loading. Do not close the window.
            </LoadingText>
          </Spacer>
        )}

        {status === "success" && (
          <>
            <Typography gutterBottom>Your order has been placed.</Typography>
            <Button fullWidth onClick={() => navigate("/")}>
              Return home
            </Button>
          </>
        )}

        {status === "error" && (
          <>
            <Typography>
              Oops, something went wrong. Please{" "}
              <a href="/contact-us">contact us</a>.
            </Typography>
          </>
        )}
      </Edges>
    </Spacer>
  )
}

const Container = styled.div`
  max-width: 800px;
`

const Content = styled.div`
  width: 100%;
`

const ItemsHeader = styled.div`
  display: flex;
  padding-bottom: 20px;

  .ItemsHeaderProduct {
    flex: 1;
  }

  .ItemsHeaderPrice {
    display: none;
    @media (min-width: 400px) {
      width: 100px;
      display: block;
    }
  }

  .ItemsHeaderAmount {
    display: none;
    @media (min-width: 400px) {
      width: 50px;
      display: block;
    }
  }

  .ItemsHeaderTotal {
    display: none;
    @media (min-width: 400px) {
      width: 80px;
      text-align: right;
      display: block;
    }
  }
`

const Items = styled.div`
  flex: 1;
`

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .Item {
    align-items: center;
  }

  .ItemImage {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  .ItemText {
    flex: 1;
  }

  .ItemMeta {
    @media screen and (max-width: 401px) {
      justify-content: space-between;
    }
    display: flex;
  }

  .ItemTitle {
    font-size: 18px;
    @media (min-width: 400px) {
      line-height: 2;
    }
  }

  .ItemContent {
    display: flex;
    flex-direction: column;
    @media (min-width: 400px) {
      flex-direction: row;
    }
  }

  .ItemPrice {
    @media (min-width: 400px) {
      width: 100px;
    }
    display: flex;
    align-items: center;
  }

  .ItemAdd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    &:before {
      display: block;
      content: "QTY";
    }
    @media (min-width: 400px) {
      &:before {
        display: none;
      }
      width: unset;
      justify-content: flex-start;
    }
  }
`

const Divider = styled.hr``

const Total = styled(Typography)`
  text-align: right;
`

const PayPalContainer = styled.div`
  width: 300px;
`

const LoadingText = styled(Typography)`
  text-align: center;
  margin-top: 30px;
`

const PaymentTitle = styled(Typography)`
  @media (min-width: 400px) {
    margin: 60px 0;
  }
  margin: 30px 0;
`

export default Checkout
