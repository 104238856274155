import * as appServices from "./appServices"

export { appServices }

export const getRequest = async (endpoint, token = "") => {
  const response = await fetch(
    `https://admin.vancouverchoir.ca/wp-json/wordsby/v2/${endpoint}`,
    {
      method: "get",
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  )

  if ((await response.status) !== 200 && (await response.status) !== 201) {
    throw new Error("Failed!")
  }

  const json = await response.json()

  return json
}

export const postRequest = async (endpoint, data, token = "") => {
  const response = await fetch(
    `https://admin.vancouverchoir.ca/wp-json/wordsby/v2/${endpoint}`,
    {
      method: "post",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }
  )

  if ((await response.status) !== 200 && (await response.status) !== 201) {
    throw new Error("Failed!")
  }

  const json = await response.json()

  return json
}
